export const copywriting = [
  {
    name: "Believe! Registration",
    pdf_path: "/b2b/Believe! Registration.pdf",
    image: "/b2b/Believe! Registration.jpg",
  },
  {
    name: "Breakthrough to YOU!",
    pdf_path: "/b2b/Breakthrough to YOU!.pdf",
    image: "/b2b/Breakthrough to YOU!.png",
  },
  {
    name: "Flourish - The Mastermind",
    pdf_path: "/b2b/Flourish - The Mastermind.pdf",
    image: "/b2b/Flourish - The Mastermind.jpg",
  },
  {
    name: "I Am Love: A Self-Love Workshop to Generate Greater Love from Within",
    pdf_path:
      "/b2b/I Am Love_ A Self-Love Workshop to Generate Greater Love from Within.pdf",
    image:
      "/b2b/I Am Love_ A Self-Love Workshop to Generate Greater Love from Within.jpg",
  },
  {
    name: "Manifest YOU",
    pdf_path: "/b2b/Manifest YOU.pdf",
    image: "/b2b/Manifest YOU.png",
  },
  {
    name: "Reverse Engineer Your Quantum Leap",
    pdf_path: "/b2b/Reverse Engineer Your Quantum Leap.pdf",
    image: "/b2b/Reverse Engineer Your Quantum Leap.png",
  },
  {
    name: "Success! Learn the Secrets to Quantum Leap in 2024",
    pdf_path: "/b2b/Success! Learn the Secrets to Quantum Leap in 2024.pdf",
    image: "/b2b/Success! Learn the Secrets to Quantum Leap in 2024.png",
  },
  {
    name: "Your Life, Your Script",
    pdf_path: "/b2b/Your Life, Your Script.pdf",
    image: "/b2b/Your Life, Your Script.jpg",
  },
  {
    name: "Soul Sparks Press – In Pursuit of 1 Million Souls Sparked",
    pdf_path: "/b2b/Soul Sparks Press – In Pursuit of 1 Million Souls Sparked.pdf",
    image: "/b2b/Soul Sparks Press – In Pursuit of 1 Million Souls Sparked.png",
  },
];
