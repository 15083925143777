import React from "react";
import { articles } from "assets/articles";
import Image from "components/Image";
import Record from "assets/images/record.png";
import Mortgage from "assets/images/mortgage.png";
import Utility from "assets/images/utility.png";
import Study from "assets/images/study.png";
import Pandemic from "assets/images/pandemic.png";
import Housing from "assets/images/housing.png";
import Eviction from "assets/images/eviction.webp";
import BidenSign from "assets/images/biden-sign.webp";
import MakeSaving from "assets/images/make-saving.webp";
import BidenAction from "assets/images/biden-actioin.webp";
import BestPlaces from "assets/images/best-places.webp";
import MortgageRate from "assets/images/mortgage-rate.webp";
import WhatToDo from "assets/images/what-do-do.webp";
import KeySocial from "assets/images/key-social.webp";
import History from "assets/images/history.png";
import NumberHomes from "assets/images/number-homes.webp";
import MortgageHit from "assets/images/mortgage-rate-hit.webp";
import ExtremelyDistruptive from "assets/images/extremely-disrcuptive.webp";
import Coronavirus from "assets/images/coronavirus.webp";
import HomeEquity from "assets/images/home-equity.webp";
import MortgageSink from "assets/images/mortgage-rate-sink.webp";
import FannieMae from "assets/images/fannie-mae.webp";
import NewStimulus from "assets/images/new-stimulus.webp";
import MortgageDrop from "assets/images/mortgage-rate-drop.webp";
import HomeRenovations from "assets/images/home-renovations.webp";
import HousingTrend from "assets/images/housing-trend.webp";
import SocialSecurity from "assets/images/social-security.webp";
import Bitcoin from "assets/images/bitcoin.webp";
import Homeowners from "assets/images/homeowners.webp";
import MortgageRateHit from "assets/images/mortgage-rate-hit.webp";
import ExpirationState from "assets/images/expiration-state.webp";
import Renegotiate from "assets/images/renegotiate.webp";
import BidenPlan from "assets/images/biden-plan.webp";
import { Link } from "react-router-dom";

const Financial = () => {
  return (
    <div className="mt-[16px] lg:mt-[60px] w-[80%] lg:w-full mx-auto relative">
      <section className="py-[100px]">
        <div className="w-[90%] lg:w-[62%] grid grid-cols-1 lg:grid-cols-3 gap-[20px] mx-auto mt-[30px] lg:mt-0">
          <a
            href="https://www.cashay.com/near-record-low-mortgage-rates-should-you-cash-out-and-refinance-192622985.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative  flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Record} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Near record low mortgage rates: Should you cash out and refinance?
            </p>
          </a>
          <a
            href="https://www.cashay.com/mortgage-rates-hit-alltime-low-is-it-time-to-refinance-144759803.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Mortgage} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates near all-time lows: Is it time to refinance?
            </p>
          </a>
          <a
            href="https://money.yahoo.com/utility-shutoffs-on-the-rise-winter-moratoriums-expire-195047058.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Utility} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Utility shut-offs appear on the rise even before winter
              moratoriums expire
            </p>
          </a>
          <a
            href="https://www.cashay.com/retirees-more-than-doubled-their-debt-2020-155109185.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Study} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Study: Retirees more than doubled their debt in 2020
            </p>
          </a>
          <a
            href="https://www.cashay.com/how-biden-stimulus-proposal-could-help-renters-213531866.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Pandemic} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's what else needs to be done to help renters during the
              pandemic
            </p>
          </a>
          <a
            href="https://money.yahoo.com/eviction-protections-covid-deaths-200645209.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Housing} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Housing expert on price increases: 'I don’t see that slowing down
              anytime soon'
            </p>
          </a>
          <a
            href="https://money.yahoo.com/eviction-protections-covid-deaths-200645209.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Eviction} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Eviction protections could have cut COVID-19 deaths by 40.7%,
              study finds
            </p>
          </a>
          <a
            href="https://money.yahoo.com/biden-signs-executive-memo-fair-housing-201338351.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={BidenSign} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Biden signs executive memo to determine if Trump-era actions
              ‘undermined fair...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/saving-easy-retirement-planning-tips-biden-era-183743301.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MakeSaving} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              ‘Make saving very easy’: Retirement planning tips for the Biden
              era
            </p>
          </a>
          <a
            href="https://money.yahoo.com/bidens-executive-action-eviction-moratorium-204447230.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={BidenAction} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Biden’s executive action to extend eviction moratorium is a
              ‘Band-Aid’...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/best-places-to-retire-in-2021-181112459.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={BestPlaces} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here are the world’s best places to retire in 2021
            </p>
          </a>
          <a
            href="https://money.yahoo.com/housing-predictions-for-2021-164625030.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MortgageRate} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates to home prices: 8 housing predictions for 2021
            </p>
          </a>
          <a
            href="https://www.cashay.com/heres-what-to-do-if-you-plan-to-buy-a-house-in-2021-205709133.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={WhatToDo} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's what to do if you plan to buy a house in 2021
            </p>
          </a>
          <a
            href="https://money.yahoo.com/social-security-changes-in-2021-200846448.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={KeySocial} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Key Social Security changes you should know about in 2021
            </p>
          </a>
          <a
            href="https://www.cashay.com/heres-the-history-of-the-30-year-fixed-mortgage-rate-184824747.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={History} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's the history of the 30-year fixed mortgage rate
            </p>
          </a>
          <a
            href="https://money.yahoo.com/homes-for-sale-hit-low-214116976.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={NumberHomes} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Number of homes for sale hit all-time low
            </p>
          </a>
          <a
            href="https://money.yahoo.com/mortgage-rates-hit-record-low-to-start-2021-191555305.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MortgageHit} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates hit record low to start 2021
            </p>
          </a>
          <a
            href="https://money.yahoo.com/pandemic-disruptive-for-retirement-plans-211526320.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={ExtremelyDistruptive} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Pandemic has been 'extremely disruptive' for retirement plans...
            </p>
          </a>
          <a
            href="https://www.cashay.com/new-coronavirus-stimulus-deal-means-for-your-wallet-154402980.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Coronavirus} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's what the new coronavirus stimulus deal means for your
              wallet
            </p>
          </a>
          <a
            href="https://money.yahoo.com/home-equity-wealth-in-america-is-booming-150510787.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={HomeEquity} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Home equity wealth in America is booming
            </p>
          </a>
          <a
            href="https://money.yahoo.com/mortgage-rates-sink-to-new-low-163330459.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MortgageSink} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates sink to 2.66% — a new low — on Christmas Eve
            </p>
          </a>
          <a
            href="https://money.yahoo.com/fannie-mae-and-freddie-mac-extend-renter-protections-212915719.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={FannieMae} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Fannie Mae and Freddie Mac extend renter protections for 8.6
              million...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/new-stimulus-deal-could-help-renters-173641316.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={NewStimulus} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's how the new stimulus deal could help struggling renters...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/mortgage-rates-new-low-195251795.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MortgageDrop} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates dropped to a new low for the 15th time this year
            </p>
          </a>
          <a
            href="https://www.cashay.com/what-you-should-know-about-home-renovations-172651002.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={HomeRenovations} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              What you should know about home renovations — both professional...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/housing-trend-home-equity-experts-worried-193513738.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={HousingTrend} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Housing trend driven by 'sizzle of getting cash now' has
              experts...
            </p>
          </a>
          <a
            href="https://money.yahoo.com/social-security-ponzi-scheme-180625728.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={SocialSecurity} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              2 in 5 Americans under 60 believe Social Security is a Ponzi
              scheme...
            </p>
          </a>
          <a
            href="https://www.cashay.com/bitcoin-retirement-investments-192200853.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Bitcoin} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Here's how to incorporate Bitcoin into your retirement investments
            </p>
          </a>
          <a
            href="https://money.yahoo.com/homeowners-face-new-fee-when-refinancing-213221281.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Homeowners} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Homeowners now face a new fee when refinancing
            </p>
          </a>
          <a
            href="https://money.yahoo.com/mortgage-rates-hit-low-refinance-fee-180313490.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={MortgageRateHit} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Mortgage rates hit another record low as new refinance fee kicks
              in
            </p>
          </a>
          <a
            href="https://money.yahoo.com/eviction-protections-434000-extra-covid-19-cases-195431207.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={ExpirationState} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Expiration of state eviction protections led to 434,000...
            </p>
          </a>
          <a
            href="https://www.cashay.com/renegotiate-a-lease-during-the-pandemic-201826959.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={Renegotiate} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              How to renegotiate a lease with your landlord during the pandemic
            </p>
          </a>
          <a
            href="https://money.yahoo.com/biden-plan-retirement-savings-181837629.html"
            target="_blank"
            className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
          >
            <div className="relative group-hover:opacity-40">
              <img src={BidenPlan} />
            </div>
            <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
              Biden’s plans will help those ‘fearful of outliving their...
            </p>
          </a>
          {articles.map((article, index) => (
            <a
              key={index}
              href={article.pdf_path}
              target="_blank"
              className="group text-transparent hover:text-[rgba(0,0,0,0.79)] relative flex justify-center items-center cursor-pointer"
              download
            >
              <div className="relative group-hover:opacity-40">
                <Image src={article.preview} alt={article.name} />
              </div>
              <p className="absolute text-[18px] font-[700] uppercase text-center w-[80%]">
                {article.name}
              </p>
            </a>
          ))}
        </div>

        
      </section>
    </div>
  );
};

export default Financial;
